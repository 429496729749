import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { Container, Divider, Grid, Typography } from "@material-ui/core";
import Text from "../data-display/text";
import classNames from "classnames";
import { useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Link from "gatsby-link";
import iphone from "../../images/iphone.jpg";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import "./blogs-card.css";
import { Box } from "react-feather";
import moment from "moment";

const useStyles = makeStyles(() => ({
  cardTitle: {
    position: "absolute",
    bottom: "24px",
    left: "24px",
    marginRight: "24px",
    transitionDuration: "0.3s",
    transitionTimingFunction: "cubic-bezier(1,.47,.39,.82)",
  },
  root: {
    maxWidth: 345,
    width: "315px",
  },
  media: {
    height: 200,
  },
  cardTitleActive: {
    transform: "translateY(-32px)",
    transitionDuration: "0.3s",
    transitionTimingFunction: "cubic-bezier(1,.47,.39,.82)",
  },
  viewProject: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    bottom: "24px",
    left: "24px",
    marginRight: "24px",
    opacity: "0",
    transitionDuration: "0.3s",
    transitionDelay: "0s",
  },
  viewProjectActive: {
    transitionDelay: "0.2s",
    transitionDuration: "0.3s",
    opacity: "1",
  },
  card: {
    "&:hover": {
      color: "white",
      backgroundColor: "black",
      borderColor: "rgba(0,165,234,0.1)",
      boxShadow: "none",
    },
  },
}));

const BlogsCard = ({
  title,
  smallMargin = false,
  inView = true,
  paddingTop,
  width = "calc(42% - -38.3px)",
  subtitle,
  image,
  author,
  updateAt,
  speed,
  to,
  style = {},
}: {
  style?: React.CSSProperties;
  inView?: boolean;
  title: string;
  subtitle: string;
  width?: string;
  paddingTop?: string;
  smallMargin?: boolean;
  image?: string;
  hoverimage?: string;
  speed?: string;
  featuredPhoto?: string;
  headline?: string;
  visible?: boolean;
  author?: string;
  updateAt?: Date;
  to: string;
}) => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));

  const xPaddingTop = paddingTop ? paddingTop : xsDown ? "0%" : "0%";

  const [hover, setHover] = useState(false);
  const classes = useStyles();
  return (
    <>
      <Link style={{ textDecoration: "none", cursor: "pointer" }} to={to}>
        <Card className={classes.root}>
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image={!image ? iphone : image}
              title="Contemplative Reptile"
            />
            <CardContent>
              <Grid container spacing={2} style={{ paddingLeft: "10px" }}>
                <p
                  style={{
                    background: "rgb(78, 62, 253)",
                    padding: "5px",
                    color: "white",
                  }}
                >
                  News{" "}
                </p>
              </Grid>

              <Typography gutterBottom variant="h5" component="h2">
              {title?.length >20 ? title?.slice(0, 20) :title}

               {title?.length > 20 ? '...' : ''}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {subtitle}
              </Typography>
            </CardContent>
          </CardActionArea>
          <Divider />
          <CardActions style={{ justifyContent: "space-between" }}>
            <Button size="small" style={{ color: "gray" }}>
              {author}
            </Button>
            <Button size="small" style={{ color: "gray" }}>
              {moment(updateAt).format("MM-DD-YYYY")}
            </Button>
          </CardActions>
        </Card>
      </Link>
      {/* <div
        className="card"
        style={{
          width: width,
          marginBottom: smallMargin ? "0px" : "72px",
          paddingTop: xPaddingTop,
          ...style,
        }}
        data-scroll={true}
        data-scroll-speed={speed}
        data-scroll-position={"top"}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        
        <Container>
          <div>
            <div className="container" style={{}}>
              <Link
                style={{ textDecoration: "none", cursor: "pointer" }}
                to={to}
              >
                <img
                  src={!image ? iphone : image}
                  alt="Avatar"
                  style={{
                    width: "100%",
                    marginTop: "20px",
                    objectFit: "cover",
                  }}
                />
                <div />
                <div>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={8}>
                      <p>News | 2min Read</p>
                    </Grid>
                  </Grid>

                  <Text
                    variant={"h6"}
                    component={"p"}
                    medium
                    style={{ marginBottom: "4px", color: "black" }}
                  >
                    {title}
                  </Text>
                </div>

                <div
                  className={classNames({
                    [classes.viewProject]: true,
                    [classes.viewProjectActive]: hover || xsDown,
                  })}
                ></div>
              </Link>
              <Grid
                container
                style={{ paddingBottom: "20px", paddingTop: "10px" }}
              >
                <Grid
                  item
                  xl={3}
                  lg={3}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: xsDown ? "unset" : "#D1D1D1",
                    borderRadius: "30px",
                    textAlign: "center",
                    width: "100%",
                    paddingTop: "2px",
                    PaddingBottom: "2px",
                    paddingRight: "4px",
                    paddingLeft: "4px",
                  }}
                >
                  <Link to={to}>
                    <Text
                      variant={"caption"}
                      component={"p"}
                      className="subtitle"
                      style={{
                        color: "black",
                        opacity: "0.8",
                        cursor: "pointer",
                        marginBottom: "5px",
                        marginTop: "5px",
                      }}
                    >
                      {subtitle}
                    </Text>
                  </Link>
                </Grid>
                <Grid
                  item
                  xl={3}
                  lg={3}
                  style={{ marginLeft: "10px", marginTop: "3px" }}
                ></Grid>
              </Grid>
            </div>
          </div>
        </Container>
      </div> */}
    </>
  );
};

export default BlogsCard;
