import React, { useEffect, useState } from "react";
import { Container, Grid, Typography, useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import BlogsCard from "./blogs-card";
import { graphql, useStaticQuery } from "gatsby";
import BlogsModel from "./blogs-model";
import syde from "../../images/sydee.png";
import Text from "../data-display/text";
import BlogSydeStory from "./BlogsydeStory";

const BlogsSection = () => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const lgDown = useMediaQuery(theme.breakpoints.down("md"));

  const [activeName, setActiveName] = useState<string>("");

  const blogsData = useStaticQuery(graphql`
    {
      blogs: allBlogs {
        edges {
          node {
            author
            avatar
            content
            subTitle
            updateAt
            image
            slug
            title
          }
        }
      }
    }
  `);

  let blogs: BlogsModel[] =
    blogsData?.blogs?.edges?.map((el) => new BlogsModel(el.node)) ?? [];

  return (
    <>
      <>
        <div style={{}} data-scroll={true} />
        {!xsDown &&
          blogs.map((_, i) => {
            let j = i * 3;
            if (j < blogs.length)
              return (
                <>
                  <div>
                    {[j].map((index, k) => {
                      if (index < blogs.length) {
                        const p = blogs[index];

                        return (
                          <React.Fragment key={p.slug}>
                            <div
                              style={{
                                width: "100%",
                                background: `url(${syde})  no-repeat`,
                                backgroundPosition: " center",
                                backgroundSize: "cover",
                                minHeight:'90vh'
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                
                                }}
                              >
                                <Typography
                                  variant={"h1"}
                                  style={{ color: "white", fontWeight: "bold",marginTop:'80px' }}
                                >
                                  News & Articls
                                </Typography>
                              </div>

                              {/* <Text
                                  variant={"h1"}
                                  black
                                  style={{ color: "white" }}
                                >
                                  pa
                                </Text>
                                <div style={{ height: "8px" }} />
                                <Text
                                  style={{
                                    marginBottom: "6px",
                                    maxWidth: "80%",
                                    color: "white",
                                  }}
                                  variant={lgDown ? "body2" : "h6"}
                                  regular
                                >
                                  jjjjj
                                </Text> */}
                            </div>
                            <Container
                              maxWidth="lg"
                              
                            >
                              <BlogSydeStory
                                inView={index === 1}
                                smallMargin
                                style={{
                                  transform:
                                    k === 2
                                      ? `translateY(90px)`
                                      : k === 1
                                      ? "translateY(100px)"
                                      : "",
                                }}
                                key={p.slug}
                                title={p.title}
                                subtitle={p.subTitle}
                                image={p.image}
                                author={p.author}
                                updateAt={p.updateAt}
                                to={`/blogs/${p.slug}/`}
                              />
                            </Container>
                          </React.Fragment>
                        );
                      } else {
                        return <></>;
                      }
                    })}
                  </div>

                  <Container maxWidth="lg" style={{marginTop:'30px'}}>
                    <div style={{ display: "flex" }} key={`${i}_projectRow`}>
                      {[j + 1 , j+2 , j+3].map((index, k) => {
                        if (index < blogs.length) {
                          const p = blogs[index];

                          return (
                            <React.Fragment key={p.slug}>
                              <BlogsCard
                                inView={index > 1}
                                smallMargin
                                style={{
                                  transform:
                                    k === 2
                                      ? `translateY(90px)`
                                      : k === 1
                                      ? "translateY(100px)"
                                      : "",
                                }}
                                key={p.slug}
                                title={p.title}
                                subtitle={p.subTitle}
                                image={p.image}
                                author={p.author}
                                updateAt={p.updateAt}
                                to={`/blogs/${p.slug}/`}
                              />
                              {<div style={{ width: "32px" }} />}
                            </React.Fragment>
                          );
                        } else {
                          return <></>;
                        }
                      })}
                    </div>
                  </Container>
                </>
              );
          })}
        <div style={{ height: xsDown ? "24px" : "152px" }} data-scroll={true} />
        <div data-scroll={true} />
        {!xsDown && blogs.length % 3 !== 1 && (
          <div style={{ height: "160px" }} data-scroll={true} />
        )}
      </>
      {xsDown &&
        blogs.map((p) => {
          return (
            <>
              <>
                <BlogsCard
                  inView
                  width={"90%"}
                  style={{
                    marginLeft: "20px",
                  }}
                  smallMargin
                  key={p.slug}
                  title={p.title}
                  subtitle={p.subTitle}
                  image={p.image}
                  to={`/blogs/${p.slug}/`}
                />
              </>
            </>
          );
        })}
      <div style={{ height: xsDown ? "24px" : "64px" }} data-scroll={true} />
      <div style={{ height: "64px" }} data-scroll={true} />
      {!xsDown && blogs.length % 3 !== 1 && (
        <div style={{ height: "160px" }} data-scroll={true} />
      )}
    </>
  );
};

export default BlogsSection;
